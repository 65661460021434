import * as React from "react";
import {Breadcrumb} from "../../components/Breadcrumb";
import {Section} from "../../components/Section";
import {Seo} from "../../components/Seo";
import {generateRandomColor} from "../../util/colors";
import {PaypalButton} from "../../components/PaypalButton";
import {KofiButton} from "../../components/KofiButton";
import {PatreonButton} from "../../components/PatreonButton";
import {PageHeader} from "../../components/PageHeader";

const Support = () => {
    return (
        <>
            <Seo title="Support Me" description="Help support what I do, every byte helps!" />
            <Breadcrumb activeLabel="Support Me"/>
            <PageHeader
                title="Support Me"
                periodColor={generateRandomColor(1)}
                description="Help support what I do, every byte helps"
            />

            <Section className="mx-auto flex">
                <div className="flex gap-2 flex-wrap mx-auto">
                   <KofiButton />

                    <PatreonButton />

                    <PaypalButton />

                </div>
            </Section>
        </>
    );
};

export default Support;
